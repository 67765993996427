import styled from 'styled-components';

export const Container = styled.div`
  .font-13 {
    font-size: 13px;
  }

  .como-descobriu-checkbox {
    display: flex;
    align-items: center;
    margin: 5px 0;

    input {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    label {
      font-size: 15px;
      margin: 0;
    }
  }
`;
