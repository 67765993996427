import { gql } from 'apollo-boost';

export const COMO_DESCOBRIU_SEBRAEPLACE = gql`
  mutation ComoDescobriuSebraeplace(
    $formaDescobriu: FormaDescobriuEnum!
    $outraFormaDescobriu: String
  ) {
    comoDescobriuSebraeplace(
      comoDescobriuInput: {
        formaDescobriu: $formaDescobriu
        outraFormaDescobriu: $outraFormaDescobriu
      }
    )
  }
`;
