import React from 'react';
import classnames from 'classnames';
import { StyledSelect } from './styles';

export const Select = React.forwardRef((props, ref) => {
  let classes = classnames({
    'custom-select': true,
    'icon-img': props.icon,
    'is-valid': !props.invalid && props.isSubmitted,
    'is-invalid': props.invalid && props.isSubmitted
  });
  if (props.customClass) {
    classes += ' ' + props.customClass;
  }

  if (props.icon) {
    let classesIcon = classnames({
      'input-icon': true,
      right: !props.iconPos || props.iconPos === 'right',
      left: props.iconPos === 'left'
    });
    const images = require.context('../../styles/images/ic', true);
    const regular = images(`./ic_${props.icon}_regular.svg`);
    const info = images(`./ic_${props.icon}_info.svg`);
    return (
      <div className={classesIcon}>
        <StyledSelect
          ref={ref}
          name={props.name}
          onChange={props.onChange}
          className={classes}
          style={{ paddingLeft: 45 }}
          disabled={props.disabled}>
          {props.options &&
            props.options.length > 0 &&
            props.options.map(op => (
              <option key={op.codigo} value={op.value}>
                {op.label}
              </option>
            ))}
        </StyledSelect>

        <img
          className='ui-img-icon'
          src={regular}
          alt={props.icon ? `${props.icon}-regular` : ''}
        />
        <img
          className='ui-img-icon focus'
          src={info}
          alt={props.icon ? `${props.icon}-info` : ''}
        />
      </div>
    );
  } else {
    return (
      <select
        ref={ref}
        name={props.name}
        onChange={props.onChange}
        className={classes}
        disabled={props.disabled}>
        {props.options &&
          props.options.length > 0 &&
          props.options.map(op => (
            <option key={op.codigo} value={op.value}>
              {op.label}
            </option>
          ))}
      </select>
    );
  }
});
