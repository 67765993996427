/*
 * Todas as URLs da aplicação estão neste ENUM
 * Ao criar nova url, adicionar no enum seguindo o padrão:
 * abc-def/abc/:abc = ABCDEF_ABC_ABC
 */

export const RoutesEnum = {
  HOME: '/',
  CADASTRO: '/cadastro',
  COMODESCOBRIU: '/como-descobriu',
  CADASTROSUCESSO: '/cadastro-sucesso',
  RECUPERARCONTA: '/recuperar-conta',
  RECUPERARCONTAOPCOES: '/recuperar-conta-opcoes',
  RECUPERARCONTANOVASENHA: '/recuperar-conta-nova-senha',
  CODIGOVERIFICACAO: '/codigo-verificacao',
  CONFIRMACAOEMAIL: '/confirmacao-email'
};
