import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TipoIntegracaoLogin from './tipo-integracao-login';
import Config from '../shared/Config';
import { saveTokenLocalStorage } from '../shared/util/utilFunction';

export const SistemaAtualContext = React.createContext(
  TipoIntegracaoLogin.BUILT_IN
);

export const SistemaAtualProvider = props => {
  const [sistemaAtual, setSistemaAtual] = useState(
    TipoIntegracaoLogin.BUILT_IN
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          `${Config.apiUrl}/integracao-login/sistema-atual`
        );
        if (res.ok) {
          const json = await res.json();
          if (json.sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
            setSistemaAtual(TipoIntegracaoLogin.SEBRAE_PR_SAS_API);
          } else {
            setSistemaAtual(TipoIntegracaoLogin.BUILT_IN);
          }
          return json.sistemaAtual;
        }
      } catch (e) {
        console.error('Erro ao carregar o sistema atual de login', e);
      }
      return null;
    })().then(async value => {
      let param = null;
      if (value === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
        param = 'sas-api-token';
      }
      if (param) {
        const url = new URL(window.location.href);
        const token = url.searchParams.get(param);
        if (token) {
          try {
            const res = await axios.post(
              `${Config.apiUrl}/auth/login-token-externa`,
              { token }
            );

            if (res.status === 200) {
              saveTokenLocalStorage(
                res.data.accessToken,
                res.data.refreshToken
              );
              window.location.href = Config.plataformaUiUrl;
            }
          } catch (e) {
            console.error('Erro ao logar por token externa', e);
          }
        }
      }
    });
  }, []);

  return (
    <SistemaAtualContext.Provider value={[sistemaAtual]}>
      {props.children}
    </SistemaAtualContext.Provider>
  );
};
