import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { COMO_DESCOBRIU_SEBRAEPLACE } from './queries';
import { Input, ErrorSpan } from '../../../../components';
import i18n from '../../../../shared/util/i18n';

const FormaDescobriuEnum = {
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  GOOGLE_ANUNCIO: 'GOOGLE_ANUNCIO',
  GOOGLE_PESQUISA: 'GOOGLE_PESQUISA',
  OUTRO: 'OUTRO'
};

export default function Cadastro(props) {
  const history = useHistory();
  const [comoDescobriu, { loading }] = useMutation(COMO_DESCOBRIU_SEBRAEPLACE, {
    onCompleted(data) {
      if (data) history.push('/cadastro-sucesso');
    }
  });

  const {
    register,
    handleSubmit,
    formState,
    errors,
    watch,
    setValue
  } = useForm();

  const onSubmit = data => {
    if (!data) {
      return;
    }

    let formaDescobriu = data.formaDescobriu && data.formaDescobriu[0];

    if (!formaDescobriu) {
      return;
    }

    comoDescobriu({
      variables: {
        formaDescobriu,
        outraFormaDescobriu: data.outraFormaDescobriu
      }
    });
  };

  const handleSelect = e => {
    const { value, checked } = e.target;
    let newValue = [];
    if (checked && value) {
      newValue.push(value);
    }

    setValue('formaDescobriu', newValue, true);
  };

  const hasOptionSelected = () => {
    const op = watch('formaDescobriu');
    return op.length;
  };

  useEffect(() => {
    props.setShowFornecedor(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div className='cadastro-div'>
      <div className='text-center'>
        <h2 className='text-black'>
          <strong>{i18n.t('BEFORE_START')}</strong>
        </h2>
        <br />
        <p className='text-black'>{i18n.t('HOW_FIGURE_OUT')}</p>
      </div>

      <Container className='form-body'>
        <br />
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <div className='como-descobriu-checkbox'>
            <input
              type='checkbox'
              id={FormaDescobriuEnum.FACEBOOK}
              name='formaDescobriu'
              value={FormaDescobriuEnum.FACEBOOK}
              onChange={handleSelect}
              ref={e =>
                register(e, {
                  validate: () =>
                    hasOptionSelected() || i18n.t('TELL_US_FOUND_SEBRAEPLACE')
                })
              }
            />
            <label htmlFor={FormaDescobriuEnum.FACEBOOK}>
              {i18n.t('ANNOUNCEMENT_FACEBOOK')}
            </label>
          </div>

          <div className='como-descobriu-checkbox'>
            <input
              type='checkbox'
              id={FormaDescobriuEnum.INSTAGRAM}
              name='formaDescobriu'
              value={FormaDescobriuEnum.INSTAGRAM}
              onChange={handleSelect}
              ref={e =>
                register(e, {
                  validate: () =>
                    hasOptionSelected() || i18n.t('TELL_US_FOUND_SEBRAEPLACE')
                })
              }
            />
            <label htmlFor={FormaDescobriuEnum.INSTAGRAM}>
              {i18n.t('ANNOUNCEMENT_INSTAGRAM')}
            </label>
          </div>

          <div className='como-descobriu-checkbox'>
            <input
              type='checkbox'
              id={FormaDescobriuEnum.GOOGLE_ANUNCIO}
              name='formaDescobriu'
              value={FormaDescobriuEnum.GOOGLE_ANUNCIO}
              onChange={handleSelect}
              ref={e =>
                register(e, {
                  validate: () =>
                    hasOptionSelected() || i18n.t('TELL_US_FOUND_SEBRAEPLACE')
                })
              }
            />
            <label htmlFor={FormaDescobriuEnum.GOOGLE_ANUNCIO}>
              {i18n.t('ANNOUNCEMENT_GOOGLE')}
            </label>
          </div>

          <div className='como-descobriu-checkbox'>
            <input
              type='checkbox'
              id={FormaDescobriuEnum.GOOGLE_PESQUISA}
              name='formaDescobriu'
              value={FormaDescobriuEnum.GOOGLE_PESQUISA}
              onChange={handleSelect}
              ref={e =>
                register(e, {
                  validate: () =>
                    hasOptionSelected() || i18n.t('TELL_US_FOUND_SEBRAEPLACE')
                })
              }
            />
            <label htmlFor={FormaDescobriuEnum.GOOGLE_PESQUISA}>
              {i18n.t('SEARCH_GOOGLE')}
            </label>
          </div>

          <div className='como-descobriu-checkbox'>
            <input
              type='checkbox'
              id={FormaDescobriuEnum.OUTRO}
              name='formaDescobriu'
              value={FormaDescobriuEnum.OUTRO}
              onChange={handleSelect}
              ref={e =>
                register(e, {
                  validate: () =>
                    hasOptionSelected() || i18n.t('TELL_US_FOUND_SEBRAEPLACE')
                })
              }
            />
            <label htmlFor={FormaDescobriuEnum.OUTRO}>{i18n.t('OTHER')}</label>
          </div>
          <ErrorSpan {...errors.formaDescobriu} />

          {watch('formaDescobriu') &&
            watch('formaDescobriu').length > 0 &&
            watch('formaDescobriu').find(
              f => f === FormaDescobriuEnum.OUTRO
            ) && (
              <div className='ml-3 mt-2 font-13'>
                {i18n.t('TELL_HERE')}
                <Input
                  placeholder={i18n.t('TELL_HERE')}
                  name='outraFormaDescobriu'
                  invalid={errors.outraFormaDescobriu}
                  isSubmitted={formState.isSubmitted}
                  ref={e =>
                    register(e, {
                      required: true
                    })
                  }
                />
                <ErrorSpan {...errors.outraFormaDescobriu} />
              </div>
            )}

          <button
            type='submit'
            className='btn btn-primary rounded-pill col mt-3'
            customClass='col mt-3'
            disabled={loading && 'disabled'}>
            <div className='container-spinner'>
              <div
                className={loading && 'spinner-border spinner-border-sm mr-2'}
              />
              <div>{i18n.t('CONTINUE')}</div>
            </div>
          </button>
        </form>
      </Container>
    </div>
  );
}
