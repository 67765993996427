import styled from 'styled-components';
import Modal from 'react-overlays/Modal';

export const Backdrop = styled('div')`
  position: fixed;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(15px);
  background-color: #ffffff8c !important; /* stylelint-disable-line */
  opacity: 0.5;
`;

export const StyledModal = styled(Modal)`
  position: fixed;
  display: flex;
  z-index: 1040;
  max-height: 60%;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin-top: -200px;
  margin-left: -280px; */
  border: 1px solid #e5e5e5; /* stylelint-disable-line */
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* stylelint-disable-line */
  padding: 20px 20px 10px 20px;
  overflow: unset;

  .modal-content {
    background-color: #fefefe; /* stylelint-disable-line */
    padding: 20px 20px 0 20px;
    border: 1px solid transparent;
    width: 100%;

    .button-close {
      position: absolute !important;
      background-color: var(--red);
      width: 22px;
      height: 22px;
      right: -32px;
      top: -34px;
      border: 0;
      color: var(--white);
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      font-weight: bolder;
    }

    .modal-title {
      margin-bottom: 10px;
      line-height: 1.5;
    }

    .modal-text {
      width: 100%;
      border: 1px solid transparent;
      overflow: auto;

      ::-webkit-scrollbar {
        width: 4px;
        height: 100vh;
      }

      ::-webkit-scrollbar-track {
        box-shadow: transparent;
        border-radius: 2px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: #c2c2c2; /* stylelint-disable-line */
      }
    }
  }

  .button-footer {
    text-align: right;

    button {
      max-width: 100px;
    }
  }

  @media (max-width: 480px) {
    width: 96vw;

    .modal-content {
      padding: 20px 0px 0 0px;
    }

    max-height: 73vh;
    transform: translate(-50%, -55%);
  }

  @media (min-width: 480px) {
    width: 80% !important;
  }

  @media (min-width: 768px) {
    width: 70% !important;
  }

  @media (min-width: 992px) {
    width: 50% !important;
  }
`;

export const Container = styled.div`
  .row {
    margin-left: 5px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .termos-text {
      margin-left: 10px;

      u {
        cursor: pointer;
      }
    }
  }

  .button-disabled {
    color: rgba(0, 0, 0, 0.25); /* stylelint-disable-line */
    background: #f5f5f5; /* stylelint-disable-line */
    border-color: #d9d9d9; /* stylelint-disable-line */
    cursor: not-allowed;
  }

  .button-disabled:active {
    color: rgba(0, 0, 0, 0.25); /* stylelint-disable-line */
    background: #f5f5f5; /* stylelint-disable-line */
    border-color: #d9d9d9; /* stylelint-disable-line */
    cursor: not-allowed;
  }

  .font-size-12 {
    font-size: 12px;
  }

  .color-red {
    color: var(--red);
  }

  .color-blue {
    color: var(--blue) !important;
  }

  .place-self-center-start {
    place-self: center start;
  }

  .checkbox-wrapper {
    display: grid;
    grid-template-columns: max-content auto max-content;
    gap: 1rem;
    place-items: center;
  }
`;
