import ApolloClient from 'apollo-boost';
import { getToken } from '../shared/util/utilFunction';
import Config from '../shared/Config';

export const client = new ApolloClient({
  uri: Config.homologadoApiUrl,
  request: operation =>
    operation.setContext({
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    })
});
