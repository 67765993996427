import React from 'react';
import classnames from 'classnames';
import NotificationClose from '../styles/images/ic/ic_notification_close.svg';

export function AlertModal({ options, message, close }) {
  let classes = classnames({
    alert: true,
    'alert-danger': !options.type || options.type === 'error',
    'alert-success': options.type && options.type === 'success',
    'alert-warning': options.type && options.type === 'warning',
    'alert-info': options.type && options.type === 'info'
  });
  return (
    <div className={classes} role='alert'>
      <div className='container d-flex align-items-center justify-content-between'>
        <span>{message}</span>
        <button
          type='button'
          name='btn-alerta-close'
          className='close'
          data-dismiss='alert'
          aria-label='Close'
          onClick={close}>
          <img src={NotificationClose} alt='ic_notification_close' />
        </button>
      </div>
    </div>
  );
}
