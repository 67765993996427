import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import './styles/ui-kit.css';
import LogoSebrae from './styles/images/logo-sebrae.svg';
import styled from 'styled-components';
import backgroundImage from './styles/images/background-image-gradient.webp';
import { Button } from './components';
import Login from './pages/Login';
import { RoutesEnum } from './Routes';
import Cadastro from './pages/Cadastro/Cadastro';
import ComoDescobriu from './pages/Cadastro/components/ComoDescobriu/ComoDescobriu';
import CadastroConcluido from './pages/Cadastro/components/CadastroConcluido/CadastroConcluido';
import RecuperarContaEmail from './pages/RecuperarContaEmail';
import RecuperarContaNovaSenha from './pages/RecuperarContaNovaSenha';
import RecuperarContaCodigoVerificacao from './pages/RecuperarContaCodigoVerificacao';
import RecuperarContaOpcoes from './pages/RecuperarContaOpcoes';
import ConfirmacaoEmail from './pages/ConfirmacaoEmail';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './graphql/ApolloClientProvider';
import LogoDev from './styles/images/logo_dev.webp';
import Config from './shared/Config';
import i18n from './shared/util/i18n';
import { SistemaAtualProvider } from './integracao-login/sistema-atual-context';
import * as Sentry from '@sentry/react';

export const LoginImage = styled.div`
  width: 56%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  /* stylelint-disable-next-line */
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

export const LoginImageMobile = styled.div`
  flex-direction: row;
  flex: 0 1 auto;
  display: flex;
  position: relative;
  justify-content: center;
  overflow: hidden;
  background: transparent
    linear-gradient(
      226deg,
      var(--text-color) 0%,
      var(--color-gray-background) 49%,
      var(--color-blue-background) 100%
    )
    0% 0% no-repeat padding-box;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const RowButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    display: block !important;
  }
`;

function App() {
  const [showFornecedor, setShowFornecedor] = useState(true);
  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={client}>
        <Router basename={'/login'}>
          <div className='login login-sm'>
            <LoginImage>
              <img alt='' src={backgroundImage} className='login-image' />
              <div className='login-gradient-div login-gradient-div-lg d-flex flex-column justify-content-between media-body'>
                <div className='gradient-div position-absolute'></div>
                <div>
                  <div className='py-4'>
                    <a href={Config.plataformaUiUrl} rel='noopener noreferrer'>
                      <img src={LogoSebrae} alt='logo_login' />
                      {Config.environment === 'DEV' && (
                        <img
                          src={LogoDev}
                          style={{
                            position: 'absolute',
                            margin: '-14px -30px',
                            height: '40px',
                            width: '50px'
                          }}
                          alt=''
                        />
                      )}
                    </a>
                  </div>
                  <div className='flex-column justify-content-center align-items-center'>
                    <div className='padding-gradient-div'>
                      <h1
                        className='text-white'
                        style={{
                          lineHeight: 1.6,
                          fontSize: '32px',
                          fontWeight: 'bold',
                          letterSpacing: '0.7px',
                          marginBottom: '16px'
                        }}>
                        {i18n.t('CREATE_YOUR_ACCOUNT_ON')}
                        <span className='text-primary'>
                          {' ' + i18n.t('SEBRAEPLACE') + ' '}
                        </span>
                        {i18n.t('AND_HAVE_A_WORLD_OF_SOLUTIONS')}
                        <span className='text-primary'>
                          {' ' + i18n.t('TOTALLY_FREE') + ' '}
                        </span>
                        {i18n.t('AND_THROUGH_IT_YOU_CAN_MANAGE')}
                      </h1>
                      {showFornecedor && (
                        <h2
                          className='text-white padding-gradient-div'
                          style={{
                            fontSize: '14px',
                            letterSpacing: '0.7px',
                            lineHeight: 1.5
                          }}>
                          {i18n.t('WANTS_TO_BE_A_SEBRAEPLACE_SUPPLIER')}
                        </h2>
                      )}
                      <br />
                    </div>
                  </div>
                  <RowButton className='pb-2'>
                    {showFornecedor && (
                      <div
                        style={{ padding: 0 }}
                        className='col-md-5 mt-1 mb-2'>
                        <Link to='/cadastro' className='text-primary w-100'>
                          <Button
                            text={i18n.t('I_WANT_TO_BE_A_SUPPLIER')}
                            type='primary'
                            width={192}
                            shape='round'
                          />
                        </Link>
                      </div>
                    )}
                    {showFornecedor && (
                      <div
                        style={{ padding: 0 }}
                        className='col-md-5 mt-1 mb-2'>
                        <Link to='/cadastro' className='text-light w-100'>
                          <Button
                            text={i18n.t('QUERO_SER_AFILIADO')}
                            type='light'
                            width={192}
                            outline={true}
                            shape='round'
                          />
                        </Link>
                      </div>
                    )}
                  </RowButton>
                </div>
                <div>
                  <p className='text-white'>
                    {i18n.t('SEBRAE_ALL_RIGHTS_RESERVED')}
                  </p>
                </div>
              </div>
            </LoginImage>
            <div className='divisor d-none d-lg-block' />
            <div className='d-flex media-body flex-column vh-100'>
              <LoginImageMobile>
                <div className='py-4'>
                  <a href={Config.plataformaUiUrl} rel='noopener noreferrer'>
                    <img src={LogoSebrae} alt='logo_login' />
                    {Config.environment === 'DEV' && (
                      <img
                        src={LogoDev}
                        style={{
                          position: 'absolute',
                          margin: '-14px -30px',
                          height: '40px',
                          width: '50px'
                        }}
                        alt=''
                      />
                    )}
                  </a>
                </div>
              </LoginImageMobile>
              <div className='login-form py-4'>
                <SistemaAtualProvider>
                  <Switch>
                    <Route path={RoutesEnum.CADASTRO}>
                      <Cadastro setShowFornecedor={setShowFornecedor} />
                    </Route>
                    <Route path={RoutesEnum.COMODESCOBRIU}>
                      <ComoDescobriu setShowFornecedor={setShowFornecedor} />
                    </Route>
                    <Route path={RoutesEnum.CADASTROSUCESSO}>
                      <CadastroConcluido
                        setShowFornecedor={setShowFornecedor}
                      />
                    </Route>
                    <Route path={RoutesEnum.RECUPERARCONTA}>
                      <RecuperarContaEmail />
                    </Route>
                    <Route path={RoutesEnum.RECUPERARCONTAOPCOES}>
                      <RecuperarContaOpcoes />
                    </Route>
                    <Route path={RoutesEnum.RECUPERARCONTANOVASENHA}>
                      <RecuperarContaNovaSenha />
                    </Route>
                    <Route path={RoutesEnum.CODIGOVERIFICACAO}>
                      <RecuperarContaCodigoVerificacao />
                    </Route>
                    <Route path={RoutesEnum.CONFIRMACAOEMAIL}>
                      <ConfirmacaoEmail />
                    </Route>
                    <Route path={RoutesEnum.HOME}>
                      <Login setShowFornecedor={setShowFornecedor} />
                    </Route>
                  </Switch>
                </SistemaAtualProvider>
              </div>
            </div>
          </div>
        </Router>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
