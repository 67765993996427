import React from 'react';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { StyledInput } from './styles';

export const Input = React.forwardRef((props, ref) => {
  let classes = classnames({
    'form-control rounded-pill': true,
    'icon-img': props.icon,
    'is-valid': !props.invalid && props.isSubmitted,
    'is-invalid': props.invalid && props.isSubmitted
  });
  if (props.customClass) {
    classes += ' ' + props.customClass;
  }

  if (props.icon) {
    let classesIcon = classnames({
      'input-icon': true,
      right: !props.iconPos || props.iconPos === 'right',
      left: props.iconPos === 'left'
    });

    const images = require.context('../../styles/images/ic', true);
    const regular = images(`./ic_${props.icon}_regular.svg`);
    const info = images(`./ic_${props.icon}_info.svg`);

    if (props.mask) {
      return (
        <div className={classesIcon}>
          <InputMask
            mask={props.mask}
            type={props.type ? props.type : 'text'}
            className={classes}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            ref={ref}
            onChange={props.onChange}
            disabled={props.disabled}
          />
          <img
            className='ui-img-icon'
            src={regular}
            alt={props.icon ? `${props.icon}-regular` : ''}
          />
          <img
            className='ui-img-icon focus'
            src={info}
            alt={props.icon ? `${props.icon}-info` : ''}
          />
        </div>
      );
    } else {
      return (
        <div className={classesIcon}>
          <StyledInput
            type={props.type ? props.type : 'text'}
            className={classes}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            ref={ref}
            onChange={props.onChange}
            removeArrows={props.removeArrows}
            disabled={props.disabled}
          />
          <img
            className='ui-img-icon'
            src={regular}
            alt={props.icon ? `${props.icon}-regular` : ''}
          />
          <img
            className='ui-img-icon focus'
            src={info}
            alt={props.icon ? `${props.icon}-info` : ''}
          />
        </div>
      );
    }
  } else {
    if (props.mask) {
      return (
        <InputMask
          mask={props.mask}
          type={props.type ? props.type : 'text'}
          className={classes}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          ref={ref}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      );
    } else {
      return (
        <input
          type={props.type ? props.type : 'text'}
          className={classes}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          ref={ref}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      );
    }
  }
});
