import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Back from '../styles/images/ic/ic_chevron_danger.svg';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as Email } from '../styles/images/ic/ic_email.svg';
import { ReactComponent as Cell } from '../styles/images/ic/ic_cell.svg';
import axios from 'axios';
import Config from '../shared/Config';
import { getMessageError } from '../shared/util/utilFunction';
import { transformPhoneMasked } from '../shared/util/transforms';
import { Select } from '../components';
import { useAlert } from 'react-alert';
import i18n from '../shared/util/i18n';

export default function RecuperarContaOpcoes() {
  const history = useHistory();
  const alert = useAlert();
  const [identificador, setIdentificador] = useState('');
  const [opcoes, setOpcoes] = useState({});
  const [loading, setLoading] = useState(false);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState('');

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    const identificador = localStorage.getItem('identificador-recuperacao');
    localStorage.removeItem('identificador-recuperacao');
    const opcoesJson = JSON.parse(localStorage.getItem('opcoes-recuperacao'));
    localStorage.removeItem('opcoes-recuperacao');

    if (!identificador && !opcoesJson) {
      history.push('/recuperar-conta');
      return;
    }

    setIdentificador(identificador);
    setOpcoes(opcoesJson);
    if (opcoesJson.emails) {
      setOpcaoSelecionada('email');
    }
    if (!opcoesJson.emails && opcoesJson.celulares) {
      setOpcaoSelecionada('celular');
    }
  }, [history]);

  function sendRequestRecuperarPorEmail(codigoEmail) {
    return axios.get(
      `${Config.apiUrl}/recuperacao-senha/obter-por-email/${identificador}/${codigoEmail}`
    );
  }

  function sendRequestRecuperarPorCelular(codigoEmail) {
    return axios.get(
      `${Config.apiUrl}/recuperacao-senha/obter-por-celular/${identificador}/${codigoEmail}`
    );
  }

  function onSubmit({ codigo }) {
    if (!codigo) {
      return;
    }

    setLoading(true);
    if (opcaoSelecionada === 'celular') {
      sendRequestRecuperarPorCelular(codigo)
        .then(res => nextStep(res.data))
        .catch(err => alert.error(getMessageError(err)))
        .finally(() => setLoading(false));
    } else {
      sendRequestRecuperarPorEmail(codigo)
        .then(res => nextStep(res.data))
        .catch(err => alert.error(getMessageError(err)))
        .finally(() => setLoading(false));
    }
  }

  function nextStep({ uuid }) {
    localStorage.setItem('hash-recuperacao', uuid);
    localStorage.setItem('identificador-recuperacao', identificador);
    history.push('/codigo-verificacao');
  }

  function getMsgContactServiceCenter() {
    if (
      (!opcoes.emails || (opcoes.emails && opcoes.emails.length === 0)) &&
      (!opcoes.celulares || (opcoes.celulares && opcoes.celulares.length === 0))
    ) {
      return i18n.t('DOES_NOT_OPTIONS');
    }
    return i18n.t('DOES_NOT_RECOGNIZE_OPTIONS');
  }

  return (
    <div className='media-body padding-container'>
      <div className='row'>
        <img className='ml-3' src={Back} alt='ic_chevron_danger' />
        <Link
          className='d-flex align-items-end text-danger'
          to='/recuperar-conta'>
          {i18n.t('BACK')}
        </Link>
      </div>
      <div className='recuperar-conta-div'>
        <div className='text-center'>
          <h2 className='text-black'>
            <strong>{i18n.t('ACCOUNT_RECOVERY')}</strong>
          </h2>
        </div>
        <br />
        <div className='mx-3 w-100 d-flex align-items-center flex-column'>
          <div style={{ maxWidth: 315, width: '100%' }}>
            {opcoes.emails && opcoes.emails.length > 0 && (
              <div className='mb-5'>
                <div
                  className={`mini-card row my-4 p-3 pointer ${opcaoSelecionada ===
                    'email' && 'mini-card-active'}`}
                  onClick={() => setOpcaoSelecionada('email')}>
                  <Email className='icon-color-svg' />
                  <span className='texto-recuperar-senha-email-sms'>
                    {i18n.t('RECOVER_PASSWORD_BY_EMAIL')}
                  </span>
                </div>

                {opcaoSelecionada === 'email' && (
                  <form onSubmit={handleSubmit(onSubmit)} className='my-4'>
                    <Select
                      icon='email'
                      iconPos='left'
                      options={opcoes.emails.map(op => ({
                        value: op.codigo,
                        label: op.email
                      }))}
                      name='codigo'
                      ref={e => register(e, { required: true })}
                    />

                    <div className='d-flex justify-content-center'>
                      <button
                        type='submit'
                        className='btn btn-primary rounded-pill col mt-4 py-2'
                        customClass='col mt-3'
                        disabled={loading && 'disabled'}>
                        <div className='container-spinner'>
                          <div
                            className={
                              loading && 'spinner-border spinner-border-sm mr-2'
                            }
                          />
                          <div>{i18n.t('CONTINUE')}</div>
                        </div>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}

            {opcoes.celulares && opcoes.celulares.length > 0 && (
              <div>
                <div
                  className={`mini-card row my-4 p-3 pointer ${opcaoSelecionada ===
                    'celular' && 'mini-card-active'}`}
                  onClick={() => setOpcaoSelecionada('celular')}>
                  <Cell className='icon-color-svg' />
                  <span className='texto-recuperar-senha-email-sms'>
                    {i18n.t('RECOVER_PASSWORD_BY_CELLPHONE')}
                  </span>
                </div>

                {opcaoSelecionada === 'celular' && (
                  <form onSubmit={handleSubmit(onSubmit)} className='my-4'>
                    <Select
                      icon='cell'
                      iconPos='left'
                      options={opcoes.celulares.map(op => ({
                        value: op.codigo,
                        label: transformPhoneMasked(op.celular)
                      }))}
                      name='codigo'
                      ref={e => register(e, { required: true })}
                    />

                    <div className='d-flex justify-content-center'>
                      <button
                        type='submit'
                        className='btn btn-primary rounded-pill col mt-4 py-2'
                        customClass='col mt-3'
                        disabled={loading && 'disabled'}>
                        <div className='container-spinner'>
                          <div
                            className={
                              loading && 'spinner-border spinner-border-sm mr-2'
                            }
                          />
                          <div>{i18n.t('CONTINUE')}</div>
                        </div>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
        </div>

        <div>
          <p
            className='mt-4 mb-2'
            style={{
              textAlign: 'center',
              color: 'var(--secondary)',
              fontWeight: 'bold'
            }}>
            {getMsgContactServiceCenter()}
          </p>

          <p
            style={{
              textAlign: 'center',
              fontSize: 12,
              maxWidth: 400
            }}>
            {i18n.t('CONTACT_SERVICE_CENTER')}
          </p>
        </div>

        <div className='text-center mt-4'>
          <span>
            {i18n.t('CLICK')}{' '}
            <Link to='/' className='text-primary'>
              {i18n.t('HERE')}
            </Link>{' '}
            {i18n.t('BACK_TO_LOGIN')}
          </span>
        </div>
      </div>
    </div>
  );
}
