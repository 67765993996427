const Config = {
  environment: window.env.ENVIRONMENT,
  baseUrl: window.env.BASE_URL,
  apiUrl: window.env.API_URL,
  facebookId: window.env.FACEBOOK_ID,
  GoogleId: window.env.GOOGLE_ID,
  plataformaUiUrl: window.env.PLATAFORMA_UI_URL,
  homologadoApiUrl: window.env.HOMOLOGADO_API_URL,
  sentryDsn: window.env.SENTRY_DSN
};

export default Config;
