import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import * as queryString from 'query-string';
import Config from '../shared/Config';
import Check from '../styles/images/check-circle-regular.svg';
import Times from '../styles/images/times-circle-regular.svg';
import { getMessageError } from '../shared/util/utilFunction';
import i18n from '../shared/util/i18n';

export default function ConfirmacaoEmail() {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    const params = queryString.parse(window.location.search);

    if (!params) {
      localStorage.setItem(
        'mensagem-login',
        JSON.stringify({
          tipo: 'error',
          mensagem: i18n.t('ERROR_VERIFYING_EMAIL')
        })
      );
      history.push('/');
      return;
    }

    axios
      .get(`${Config.apiUrl}/confirmacao-email`, { params })
      .then(() => {
        setLoading(false);
        setErrorMessage('');
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage(getMessageError(err));
      });
  }, [history, setLoading, setErrorMessage]);

  const styleImage = {
    width: '200px',
    height: '200px'
  };

  const center = {
    alignItems: 'center'
  };

  return (
    <div className='media-body'>
      <div className='recuperar-conta-div'>
        <div className='text-center'>
          <h2 className='text-black'>
            <strong>{i18n.t('EMAIL_CONFIRMATION')}</strong>
          </h2>
        </div>
        {!loading && !errorMessage && (
          <div className='mx-3 my-5 d-flex flex-column' style={center}>
            <img src={Check} alt='check' style={styleImage} />
            <div className='mt-3'>{i18n.t('EMAIL_CONFIRMED_SUCCESSFULLY')}</div>
          </div>
        )}
        {!loading && errorMessage && (
          <div className='mx-3 my-5 d-flex flex-column' style={center}>
            <img src={Times} alt='times' style={styleImage} />
            <div className='mt-3'>{errorMessage}</div>
          </div>
        )}
        <div className='text-center' style={{ fontSize: '1.3em' }}>
          <span>
            {i18n.t('CLICK')}{' '}
            <Link to='/' className='text-primary'>
              {i18n.t('HERE')}
            </Link>{' '}
            {i18n.t('BACK_TO_LOGIN')}
          </span>
        </div>
      </div>
    </div>
  );
}
