// TODO: Implementar login do facebook e do google
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import GoogleLogin from 'react-google-login';
import { useAlert } from 'react-alert';
import { Input } from '../components';
import {
  getMessageError,
  saveTokenLocalStorage
} from '../shared/util/utilFunction';
import Config from '../shared/Config';
// import FacebookLogo from '../styles/images/ic/ic_facebook_login.svg';
// import GoogleLogo from '../styles/images/ic/ic_google_login.svg';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
// import * as jwt from 'jsonwebtoken';
import i18n from '../shared/util/i18n';
import { SistemaAtualContext } from '../integracao-login/sistema-atual-context';
import { getIdentificador, getRef } from '../integracao-login/integracao-login';
import TipoIntegracaoLogin from '../integracao-login/tipo-integracao-login';
import { useHistory } from 'react-router-dom';
import { transformCPF } from '../shared/util/transforms';

export default function Login(props) {
  let currentURL = new URL(document.location.href);
  let requestedURL = currentURL.searchParams.get('requestedURL');
  const [sistemaAtual] = useContext(SistemaAtualContext);
  const history = useHistory();

  const [errorLogin, setErroLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const PERFIL = gql`
    {
      obterPessoaAtualPorUsuarioLogado {
        vinculo {
          empresa {
            tipo
          }
        }
      }
    }
  `;

  const [getPessoaLogada, { data }] = useLazyQuery(PERFIL, {
    onCompleted() {
      if (requestedURL && requestedURL.length) {
        window.location.href = requestedURL;
      } else if (!data.obterPessoaAtualPorUsuarioLogado.vinculo) {
        window.location.href = Config.plataformaUiUrl;
      } else {
        window.location.href = Config.plataformaUiUrl + '/painel';
      }
    }
  });

  useEffect(() => {
    const storage = localStorage.getItem('mensagem-login');
    if (storage) {
      const mensagem = JSON.parse(storage);
      localStorage.removeItem('mensagem-login');
      switch (mensagem.tipo) {
        case 'info':
          alert.info(mensagem.mensagem);
          break;
        case 'success':
          alert.success(mensagem.mensagem);
          break;
        case 'error':
          alert.error(mensagem.mensagem);
          break;
        default:
          alert.show(mensagem.mensagem);
      }
    }
  }, [alert, sistemaAtual]);

  useEffect(() => {
    props.setShowFornecedor(true);
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    setError,
    errors,
    setValue
  } = useForm();

  const onSubmit = data => {
    if (!data) {
      return;
    }

    setLoading(true);
    axios
      .post(`${Config.apiUrl}/auth/login`, data)
      .then(res => {
        if (res && res.data && res.data.accessToken) {
          saveTokenLocalStorage(res.data.accessToken, res.data.refreshToken);
          setErroLogin(false);
          getPessoaLogada();
        } else {
          alert.error('Token não informada');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('username');
        setError('password');
        setErroLogin(true);
        alert.error(getMessageError(err));
        setLoading(false);
      });
  };

  // const responseFacebook = response => {
  //   if (response.status && response.status === 'unknown') {
  //     return;
  //   }

  //   if (
  //     response &&
  //     response.email &&
  //     response.name &&
  //     response.userID &&
  //     response.accessToken
  //   ) {
  //     const data = {};
  //     data.nome = response.name;
  //     data.email = response.email;
  //     data.facebookId = response.userID;

  //     if (response.picture && response.picture.data) {
  //       data.fotoUrl = response.picture.data.url;
  //     }

  //     loginPorRedeSocial(data, 'facebook', response.accessToken);
  //   } else {
  //     alert.error(i18n.t('LOGIN'));
  //   }
  // };

  // const responseGoogle = response => {
  //   if (response.error && response.error === 'popup_closed_by_user') {
  //     return;
  //   }

  //   if (response && response.tokenId && response.accessToken) {
  //     const decodedToken = jwt.decode(response.tokenId);

  //     if (decodedToken && decodedToken.sub) {
  //       const data = {};
  //       data.email = decodedToken.email;
  //       data.nome = decodedToken.name;
  //       data.googleId = decodedToken.sub;
  //       data.fotoUrl = decodedToken.picure;

  //       loginPorRedeSocial(data, 'google', response.accessToken);
  //     } else {
  //       alert.error(i18n.t('ERROR_LOGING_GOOGLE'));
  //     }
  //   } else {
  //     alert.error(
  //       response.error ? response.error : i18n.t('ERROR_LOGIN_GOOGLE')
  //     );
  //   }
  // };

  // const loginPorRedeSocial = (data, origin, token) => {
  //   const params = {
  //     origin: origin,
  //     token: token
  //   };
  //   axios
  //     .post(`${Config.apiUrl}/usuario/cadastrar-social`, data, { params })
  //     .then(res => {
  //       console.log(res.data);
  //       if (res && res.data && res.data.accessToken) {
  //         saveTokenLocalStorage(res.data.accessToken, res.data.refreshToken);
  //         getPessoaLogada();
  //         setErroLogin(false);
  //       } else {
  //         alert.error('Token não informada');
  //       }
  //     })
  //     .catch(err => {
  //       alert.error(getMessageError(err));
  //     });
  // };

  const redirectCadastro = () => {
    history.push('/cadastro');
  };

  const handleOnChangeUserName = e => {
    if (e && e.target && e.target.value) {
      if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
        setValue('username', transformCPF(e.target.value));
      } else {
        setValue('username', e.target.value);
      }
    }
  };

  return (
    <React.Fragment>
      <div className='pagina-inicial'>
        <a alt='' href={Config.plataformaUiUrl}>
          &lt; {i18n.t('HOME_PAGE')}
        </a>
      </div>
      <div className='login-div'>
        <div>
          <div className='text-center'>
            <h1 className='text-black' style={{ fontSize: '28px' }}>
              <strong>{i18n.t('LOGIN')}</strong>
            </h1>
            <br />
            {sistemaAtual === TipoIntegracaoLogin.BUILT_IN && (
              <p className='text-black'>
                {i18n.t('IDENTIFY_YOURSELF_BUILT_IN')}{' '}
                <Link
                  to={
                    requestedURL && requestedURL.length
                      ? `/cadastro?requestedURL=${requestedURL}`
                      : `/cadastro`
                  }
                  className='text-primary'>
                  <b>{i18n.t('CREATE_AN_ACCOUNT')} </b>
                </Link>{' '}
                {i18n.t('PERSONALIZED_EXPERIENCE_SEBRAEPLACE')}
              </p>
            )}
            {sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API && (
              <p className='text-black'>
                {i18n.t('USE_YOUR')} <b>{i18n.t('LOGIN_SEBRAE')} </b>
                <br />
                {i18n.t('OR_SEBRAE')}{' '}
                <Link
                  to={
                    requestedURL && requestedURL.length
                      ? `/cadastro?requestedURL=${requestedURL}`
                      : `/cadastro`
                  }
                  className='text-primary'>
                  <b>{i18n.t('CREATE_AN_ACCOUNT')} </b>
                </Link>{' '}
              </p>
            )}
          </div>
          <div className='form-body form-lg-body'>
            <br />
            <br />
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
              <Input
                icon='name'
                iconPos='left'
                placeholder={i18n.t(getIdentificador(sistemaAtual))}
                name='username'
                invalid={errors.username}
                isSubmitted={formState.isSubmitted}
                ref={e => {
                  register(e, getRef(sistemaAtual));
                }}
                onChange={handleOnChangeUserName}
              />
              <br />
              <Input
                type='password'
                icon='password'
                iconPos='left'
                placeholder={i18n.t('PASSWORD')}
                name='password'
                invalid={errors.username}
                isSubmitted={formState.isSubmitted}
                ref={register({ required: true })}
              />

              <div className='opcoesLogin'>
                <div className='recuperar-senha'>
                  <Link
                    className={errorLogin ? 'text-danger' : 'text-primary'}
                    to='recuperar-conta'>
                    {i18n.t('PASSWORD_RECOVER')}
                  </Link>
                </div>
                <div className='manter-logado'>
                  <input
                    className='ml-0'
                    type='checkbox'
                    id='keepConnected'
                    name='keepConnected'
                    isSubmitted={formState.isSubmitted}
                    ref={register()}
                  />
                  <label
                    htmlFor='keepConnected'
                    className='manter-logado-text ml-1 mb-0'>
                    {i18n.t('KEEP_ME_CONNECTED')}
                  </label>
                </div>
              </div>

              <button
                type='submit'
                className='btn btn-primary rounded-pill col mt-5'
                customClass='col mt-3'
                disabled={loading && 'disabled'}>
                <div className='container-spinner'>
                  <div
                    className={
                      loading && 'spinner-border spinner-border-sm mr-2'
                    }
                  />
                  <div>{i18n.t('ENTER')}</div>
                </div>
              </button>
              <button
                className='btn rounded-pill btn-create-account col mt-3'
                customClass='col mt-3'
                disabled={loading && 'disabled'}
                onClick={() => redirectCadastro()}>
                <div>{i18n.t('CREATE_ACCOUNT')}</div>
              </button>
            </form>
            <br />
            {/* <div className='container'>
            <div className='row d-flex align-items-center'>
              <div className='col-5'>
                <hr />
              </div>
              <div className='col-2 d-flex justify-content-center'>
                <span>{i18n.t('OR')}</span>
              </div>
              <div className='col-5'>
                <hr />
              </div>
            </div>
            <div className='row pt-4'>
              <div className='col-5 d-flex justify-content-end'>
                {Config.facebookId && (
                  <FacebookLogin
                    appId={Config.facebookId}
                    fields='name,email,picture'
                    callback={responseFacebook}
                    render={renderProps => (
                      <img
                        className='pointer'
                        src={FacebookLogo}
                        alt='ic_facebook_login'
                        onClick={renderProps.onClick}
                      />
                    )}
                  />
                )}
              </div>
              <div className='col-2 d-flex justify-content-center'></div>
              <div className='col-5 d-flex justify-content-start'>
                {Config.GoogleId && (
                  <GoogleLogin
                    clientId={Config.GoogleId}
                    render={renderProps => (
                      <img
                        className='pointer'
                        src={GoogleLogo}
                        alt='ic_google_login'
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      />
                    )}
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                )}
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
