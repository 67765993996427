export function transformCPF(value) {
  if (value) {
    value = value.toString();
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      value = value.substring(0, 11);
    }
    switch (value.length) {
      case 4:
        value = value.replace(/(\d+)(\d{1})/, '$1.$2');
        break;
      case 5:
        value = value.replace(/(\d+)(\d{2})/, '$1.$2');
        break;
      case 6:
        value = value.replace(/(\d+)(\d{3})/, '$1.$2');
        break;
      case 7:
        value = value.replace(/(\d+)(\d{3})(\d{1})/, '$1.$2.$3');
        break;
      case 8:
        value = value.replace(/(\d+)(\d{3})(\d{2})/, '$1.$2.$3');
        break;
      case 9:
        value = value.replace(/(\d+)(\d{3})(\d{3})/, '$1.$2.$3');
        break;
      case 10:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
        break;
      case 11:
        value = value.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        break;
      default:
        return value;
    }
  }
  return value;
}

export function transformPhoneMasked(num) {
  if (num) {
    num = num.toString();
    num = num.replace(/\D/g, '');
    num = num.replace(/(\d{2})(\d{2})(\d{2})/, '($1) $2***-**$3');
  }
  return num;
}
