import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Back from '../styles/images/ic/ic_chevron_danger.svg';
import { Input } from '../components';
import axios from 'axios';
import Config from '../shared/Config';
import { getMessageError } from '../shared/util/utilFunction';
import * as queryString from 'query-string';
import { useAlert } from 'react-alert';
import i18n from '../shared/util/i18n';

export default function RecuperarContaCodigoVerificacao() {
  const history = useHistory();
  const [identificador, setIdentificador] = useState('');
  const [hash, setHash] = useState('');
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    setIdentificador(localStorage.getItem('identificador-recuperacao'));
    localStorage.removeItem('identificador-recuperacao');
    setHash(localStorage.getItem('hash-recuperacao'));
    localStorage.removeItem('hash-recuperacao');
  }, [history]);

  function verificarCodigo(event) {
    event.preventDefault();

    const codigo = event.target.codigo.value;
    if (!codigo) {
      alert.error('Informe o seu código de verificação');
      return;
    }

    const params = {};
    params.identificador = identificador;
    params.codigo = codigo;
    params.hash = hash;

    setLoading(true);
    axios
      .get(`${Config.apiUrl}/recuperacao-senha/validar-codigo`, { params })
      .then(res => {
        const token = {};
        token.uuid = res.data.uuid;
        token.identificador = res.data.identificador;
        token.codigo = codigo;

        const stringified = queryString.stringify(token);
        history.push('/recuperar-conta-nova-senha?'.concat(stringified));
      })
      .catch(err => alert.error(getMessageError(err)))
      .finally(() => setLoading(false));
  }

  return (
    <div className='media-body padding-container'>
      <div className='row'>
        <img className='ml-3' src={Back} alt='ic_chevron_danger' />
        <Link
          className='d-flex align-items-end text-danger'
          to='/recuperar-conta'>
          Voltar
        </Link>
      </div>
      <div className='recuperar-conta-div'>
        <div className='text-center'>
          <h2 className='text-black'>
            <strong>Recuperação de conta</strong>
          </h2>
        </div>
        <br />
        <form className='mx-5 my-4' onSubmit={verificarCodigo}>
          <div className='text-center'>
            <span>
              <strong>Digite o código de verificação</strong>
            </span>
          </div>
          <br />
          <Input
            type='number'
            icon='cell'
            iconPos='left'
            placeholder='Código de recuperação de senha'
            name='codigo'
            removeArrows
          />
          <div className='d-flex justify-content-center'>
            <button
              type='submit'
              className='btn btn-primary rounded-pill col mt-5 py-2'
              customClass='col mt-3'
              disabled={loading && 'disabled'}>
              <div className='container-spinner'>
                <div
                  className={loading && 'spinner-border spinner-border-sm mr-2'}
                />
                <div>{i18n.t('CONTINUE')}</div>
              </div>
            </button>
          </div>
        </form>
        <br />
        <br />
        <div className='text-center mt-5 mb-3'>
          <span>
            Clique{' '}
            <Link to='/' className='text-primary'>
              aqui
            </Link>{' '}
            para voltar ao login
          </span>
        </div>
      </div>
    </div>
  );
}
