import TipoIntegracaoLogin from './tipo-integracao-login';
import { isValid } from 'cpf';
import i18n from '../shared/util/i18n';

export const enterYour = sistemaAtual => {
  if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
    return 'ENTER_YOUR_CPF';
  }
  return 'ENTER_YOUR_EMAIL';
};

export const enterToRecover = sistemaAtual => {
  if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
    return 'ENTER_CPF_TO_RECOVER';
  }
  return 'ENTER_EMAIL_TO_RECOVER';
};

export const icon = sistemaAtual => {
  if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
    return 'cpf';
  }
  return 'email';
};

export const getIdentificador = sistemaAtual => {
  if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
    return 'CPF';
  }
  return 'EMAIL';
};

export const getMask = sistemaAtual => {
  if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
    return '999.999.999-99';
  }
  return '';
};

export const getRef = sistemaAtual => {
  if (sistemaAtual === TipoIntegracaoLogin.SEBRAE_PR_SAS_API) {
    return {
      required: true,
      validate: value => isValid(value) || i18n.t('INAVLID_CPF')
    };
  }
  return { required: true };
};
