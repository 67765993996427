import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as queryString from 'query-string';
import Back from '../styles/images/ic/ic_chevron_danger.svg';
import Avatar from '../styles/images/avatar.svg';
import { Input, ErrorSpan } from '../components';
import { useForm } from 'react-hook-form';
import Config from '../shared/Config';
import { getMessageError } from '../shared/util/utilFunction';
import { useAlert } from 'react-alert';
import i18n from '../shared/util/i18n';
import axios from 'axios';
import PasswordStrengthBar from 'react-password-strength-bar';

export default function RecuperarContaNovaSenha() {
  const history = useHistory();
  const alert = useAlert();
  const [recuperacao, setRecuperacao] = useState({});
  const passwordRef = useRef(null);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    errors,
    watch,
    setError,
    triggerValidation
  } = useForm();

  useEffect(() => {
    const values = queryString.parse(window.location.search);

    if (!values || !values.identificador || !values.uuid) {
      localStorage.setItem(
        'mensagem-login',
        JSON.stringify({
          tipo: 'error',
          mensagem: i18n.t('ERROR_RECOVER_PASSWORD')
        })
      );
      history.push('/');
      return;
    }

    const recuperacaoNovaSenha = {};
    recuperacaoNovaSenha.identificador = values.identificador;
    recuperacaoNovaSenha.uuid = values.uuid;
    recuperacaoNovaSenha.codigoValidacao = values.codigo;
    setRecuperacao(recuperacaoNovaSenha);
  }, [history]);

  const onSubmit = data => {
    if (!data) {
      return;
    }

    recuperacao.novaSenha = data.senha;

    setLoading(true);
    axios
      .post(`${Config.apiUrl}/recuperacao-senha/nova-senha`, recuperacao)
      .then(post => {
        localStorage.setItem(
          'mensagem-login',
          JSON.stringify({
            tipo: 'success',
            mensagem: i18n.t('PASSWORD_SUCCESFULLY_UPDATED')
          })
        );
        localStorage.setItem('accessToken', post.data.accessToken);
        localStorage.setItem('refreshToken', post.data.refreshToken);
        window.location.href = `${Config.plataformaUiUrl}`;
      })
      .catch(err => {
        setError('senha');
        setError('confirmacaoSenha');
        alert.error(getMessageError(err));
      })
      .finally(() => setLoading(false));
  };

  const passwordChange = e => {
    setPassword(e.target.value);
  };

  const handleChangeScore = () => {
    triggerValidation('senha');
  };

  return (
    <div className='media-body padding-container'>
      <div className='row'>
        <img className='ml-3' src={Back} alt='ic_chevron_danger' />
        <Link
          className='d-flex align-items-end text-danger'
          to='/recuperar-conta'>
          {i18n.t('BACK')}
        </Link>
      </div>
      <div className='recuperar-conta-div'>
        <div className='text-center'>
          <h2 className='text-black'>
            <strong>{i18n.t('ACCOUNT_RECOVERY')}</strong>
          </h2>
        </div>
        <br />
        <div
          className='mx-5 my-4 '
          style={{
            width: 300
          }}>
          <form className='w-100' onSubmit={handleSubmit(onSubmit)}>
            <div className='mini-card text-center p-3'>
              <img src={Avatar} className='avatar' alt='avatar' />
              <div className='nome-vazio mt-2 mx-4'></div>
            </div>
            <br />
            <br />
            <div className='text-center'>
              <span>
                <strong>{i18n.t('ENTER_NEW_PASSWORD')}</strong>
              </span>
            </div>
            <br />
            <Input
              type='password'
              icon='password'
              iconPos='left'
              placeholder={i18n.t('PASSWORD')}
              name='senha'
              invalid={errors.senha}
              isSubmitted={formState.isSubmitted}
              onChange={passwordChange}
              ref={register({
                required: true,
                validate: () => {
                  return (
                    (passwordRef &&
                      passwordRef.current &&
                      passwordRef.current.state &&
                      passwordRef.current.state.score > 1) ||
                    'A força da senha deve ser pelo menos Média'
                  );
                }
              })}
            />

            <ErrorSpan {...errors.senha} />

            {watch('senha') && watch('senha').length > 0 && (
              <PasswordStrengthBar
                password={password}
                scoreWords={[
                  i18n.t('WEAK'),
                  i18n.t('FAIR'),
                  i18n.t('GOOD'),
                  i18n.t('STRONG'),
                  i18n.t('VERY_STRONG')
                ]}
                shortScoreWord={i18n.t('TOO_SHORT')}
                onChangeScore={score => handleChangeScore(score)}
                ref={passwordRef}
              />
            )}
            <br />
            <Input
              type='password'
              icon='password'
              iconPos='left'
              placeholder={i18n.t('CONFIRM_PASSWORD')}
              name='confirmacaoSenha'
              invalid={errors.confirmacaoSenha}
              isSubmitted={formState.isSubmitted}
              ref={register({
                required: true,
                validate: value =>
                  value === watch('senha') || i18n.t('PASSWORD_NOT_MATCH')
              })}
            />

            <ErrorSpan {...errors.confirmacaoSenha} />

            <br />
            <div className='d-flex justify-content-center'>
              <button
                type='submit'
                className='btn btn-primary rounded-pill col py-2'
                customClass='col mt-3'
                disabled={loading && 'disabled'}>
                <div className='container-spinner'>
                  <div
                    className={
                      loading && 'spinner-border spinner-border-sm mr-2'
                    }
                  />
                  <div>{i18n.t('CONTINUE')}</div>
                </div>
              </button>
            </div>
            <br />
            <br />
            <br />
            <div className='text-center'>
              <span>
                {i18n.t('CLICK')}{' '}
                <Link to='/' className='text-primary'>
                  {i18n.t('HERE')}
                </Link>{' '}
                {i18n.t('BACK_TO_LOGIN')}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
