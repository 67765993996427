import React from 'react';
import styled from 'styled-components';

export function ErrorSpan(props) {
  const InvalidFeedback = styled.div`
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    margin-left: 1rem;
  `;

  return (
    <InvalidFeedback>
      {props.type === 'required' ? 'Campo Obrigatório' : props.message}
    </InvalidFeedback>
  );
}
