const getMessageError = err => {
  if (err && err.response && err.response.data && err.response.data.message) {
    return err.response.data.message;
  } else if (err && err.message) {
    return err.message;
  } else {
    return 'Erro não identificado';
  }
};
const saveTokenLocalStorage = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

const getToken = () => localStorage.getItem('accessToken');

module.exports = {
  getMessageError,
  saveTokenLocalStorage,
  getToken
};
