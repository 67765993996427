import React from 'react';
import classnames from 'classnames';

export function Button(props) {
  let classes = classnames({
    btn: true,
    // Tipos
    'btn-primary': !props.type || props.type === 'primary',
    'btn-outline-primary':
      props.type && props.type === 'primary' && props.outline,
    'btn-secondary': props.type && props.type === 'secondary',
    'btn-outline-secondary':
      props.type && props.type === 'secondary' && props.outline,
    'btn-success': props.type && props.type === 'success',
    'btn-outline-success':
      props.type && props.type === 'success' && props.outline,
    'btn-danger': props.type && props.type === 'danger',
    'btn-outline-danger':
      props.type && props.type === 'danger' && props.outline,
    'btn-warning text-white': props.type && props.type === 'warning',
    'btn-outline-warning':
      props.type && props.type === 'warning' && props.outline,
    'btn-info': props.type && props.type === 'info',
    'btn-outline-info': props.type && props.type === 'info' && props.outline,
    'btn-outline-light': props.type && props.type === 'light' && props.outline,
    'btn-dark': props.type && props.type === 'dark',
    'btn-outline-dark': props.type && props.type === 'dark' && props.outline,
    'btn-link': props.type && props.type === 'link',
    'btn-outline-link': props.type && props.type === 'link' && props.outline,
    // Estilos
    'rounded-pill': props.shape && props.shape === 'round',
    'rounded-circle': props.shape && props.shape === 'circle',
    // Tamanho
    'btn-sm': props.size && props.size === 'small',
    'btn-lg': props.size && props.size === 'large'
  });

  if (props.customClass) {
    classes += ' ' + props.customClass;
  }

  return (
    <button
      type={props.submit ? 'submit' : 'button'}
      className={classes}
      style={{
        width: props.width ? props.width : 'auto'
      }}
      onClick={props.onClick}>
      {props.text}
    </button>
  );
}
