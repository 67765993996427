const login = {
  HOME_PAGE: 'Página Inicial',
  LOGIN: 'Login',
  IDENTIFY_YOURSELF:
    'Identifique-se e tenha uma experiência personalizada na plataforma',
  PASSWORD_RECOVER: 'Recuperar senha',
  ENTER: 'Entrar',
  OR: 'ou',
  ERROR_LOGIN_FACEBOOK: 'Erro ao realizar login utilizando o Facebook',
  ERROR_LOGIN_GOOGLE: 'Erro ao realizar login utilizando o Google',
  TO_CREATE_NEW_ACCOUNT: 'para criar uma nova conta',
  IDENTIFY_YOURSELF_BUILT_IN: 'Identifique-se ou ',
  KEEP_ME_CONNECTED: 'Mantenha-me conectado',
  CREATE_AN_ACCOUNT: 'crie uma conta',
  PERSONALIZED_EXPERIENCE_SEBRAEPLACE:
    ' e tenha uma experiência personalizada no Sebraeplace.',
  USE_YOUR: 'Use seu  ',
  LOGIN_SEBRAE: 'login Sebrae/PR',
  OR_SEBRAE: 'ou ',
  CREATE_ACCOUNT: 'Criar conta'
};

const cadastro = {
  CREATE_YOUR_ACCOUNT: 'Crie sua conta',
  REGISTER_FOR_A_PERSONALIZED_EXPERIENCE:
    'Faça seu cadastro para ter uma experiência personalizada no',
  FULL_NAME: 'Nome Completo',
  CPF: 'CPF',
  CELLPHONE: 'Celular',
  CREATE_VALID_PASSWORD:
    'Para criar uma senha válida, use no mínimo 6 caracteres, podendo ser letras, números e caracteres especiais. A força da senha deve ser pelo menos Média.',
  ALREADY_HAVE_ACCOUNT: 'Já tem conta no Sebrae/PR?',
  ACCESS: 'Acesse com o mesmo login.',
  WEAK: 'Fraca',
  FAIR: 'Fraca',
  GOOD: 'Média',
  STRONG: 'Forte',
  VERY_STRONG: 'Muito Forte',
  TOO_SHORT: 'Fraca',
  PASSWORD_NOT_MATCH: 'Senhas não conferem',
  FILL_FULL_NAME: 'Preencha o nome completo',
  INVALID_EMAIL: 'E-mail inválido',
  INAVLID_CPF: 'CPF inválido',
  ALREADY_HAVE_CPF: 'CPF já possui cadastro no sistema',
  INVALID_CELLPHONE: 'Telefone inválido',
  I_ACCEPT_THE: 'Aceito os',
  BY_CREATING_AN_ACCOUNT: 'Ao criar uma conta',
  SUCCESSFUL_REGISTRATION: 'Cadastro realizado com sucesso!',
  BEFORE_START: 'Antes de começar',
  HOW_FIGURE_OUT: 'Nos conte como descobriu o Sebraeplace?',
  SELECT_OPTION: 'Selecione uma opção',
  ANNOUNCEMENT_FACEBOOK: 'Anúncio no Facebook',
  ANNOUNCEMENT_INSTAGRAM: 'Anúncio no Instagram',
  ANNOUNCEMENT_GOOGLE: 'Anúncio no Google',
  SEARCH_GOOGLE: 'Procurei no Google',
  OTHER: 'Outro',
  TELL_HERE: 'Conte aqui:',
  TELL_US_FOUND_SEBRAEPLACE: 'Selecione a forma que descobriu o Sebraeplace.',
  START_NOW:
    'Comece agora mesmo a explorar as melhores soluções para o seu negócio.',
  GO_TO_PANEL: 'Seguir para o meu painel',
  CHECK_SOLUTIONS: 'Quero conhecer as soluções',
  ALREADY_EXISTS: 'Cadastro já existente.',
  DONT_EXISTS: 'Cadastro não existe.',
  DOES_NOT_RECOGNIZE_OPTIONS: 'Não reconhece as informações listadas?',
  DOES_NOT_OPTIONS:
    'Não foram encontradas informações de recuperação de senha.',
  CONTACT_SERVICE_CENTER:
    'Entre em contato com a nossa Central de Atendimento pelo telefone 0800 570 0800 ou pelo WhatsApp 0800 570 0800.',
  TRY_RECOVERY_PASSWORD: 'Tente recuperar a sua senha',
  PRIVACY_POLICY:
    'Declaro que li e estou ciente que os meus dados serão tratados conforme as diretrizes da <0>Política de Privacidade Do Sebrae</0>',
  USE_TERMS: 'Declaro que estou de acordo com o <0>Termo de Uso</0>',
  CONTENT_PROMOTION: 'Aceito receber conteúdos e promoções do Sebrae PR',
  MANDATORY: 'Obrigatório',
  OPTIONAL: 'Opcional'
};

const confirmacaoEmail = {
  EMAIL_CONFIRMATION: 'Confirmação do E-mail',
  EMAIL_CONFIRMED_SUCCESSFULLY: 'E-mail confirmado com sucesso',
  ERROR_VERIFYING_EMAIL:
    'Erro ao confirmar seu e-mail. Entre em contato com o fornecedor'
};

const recuperarContaEmail = {
  ENTER_YOUR_EMAIL: 'Informe o seu e-mail',
  ENTER_EMAIL_TO_RECOVER:
    'Digite o email da conta que você gostaria de recuperar.',
  ENTER_YOUR_CPF: 'Informe o seu CPF',
  ENTER_CPF_TO_RECOVER: 'Digite o CPF da conta que você gostaria de recuperar.'
};

const recuperarContaNovaSenha = {
  ERROR_RECOVER_PASSWORD:
    'Erro ao iniciar processo para recuperar senha. Por favor, entre em contato com o fornecedor',
  PASSWORD_SUCCESFULLY_UPDATED: 'Senha atualizada com sucesso',
  ENTER_NEW_PASSWORD: 'Digite sua nova senha'
};

const recuperarContaOpcoes = {
  DESCRIPTION_RECOVER_PASSWORD: 'Selecione uma opção para recuperar a senha',
  CHECK_YOUR_MAILBOX: 'Verifique a sua caixa postal',
  RECOVER_PASSWORD_BY_EMAIL: 'Recuperar a senha pelo email',
  RECOVER_PASSWORD_BY_CELLPHONE: 'Recuperar a senha pelo celular',
  ENTER_EMAIL: 'Digite o email para recuperar a senha',
  ENTER_CELLPHONE: 'Digite o celular para recuperar a senha'
};

const app = {
  CREATE_YOUR_ACCOUNT_ON: 'Crie sua conta no',
  SEBRAEPLACE: 'Sebraeplace',
  AND_HAVE_A_WORLD_OF_SOLUTIONS:
    'e tenha um mundo de soluções para seu negócio! Cadastre-se',
  TOTALLY_FREE: 'gratuitamente',
  AND_THROUGH_IT_YOU_CAN_MANAGE: 'e gerencie seu perfil, compras e muito mais!',
  WANTS_TO_BE_A_SEBRAEPLACE_SUPPLIER:
    'Aproveite as modalidades que o Sebraeplace disponibiliza para você. Crie sua conta, cadastre a sua empresa e conheça as condições!',
  I_WANT_TO_BE_A_SUPPLIER: 'Quero ser fornecedor',
  SEBRAE_ALL_RIGHTS_RESERVED: 'Sebrae/PR - Todos os direitos reservados',
  QUERO_SER_AFILIADO: 'Quero ser afiliado'
};

const shared = {
  SEBRAE: 'SEBRAE',
  EMAIL: 'Email',
  PASSWORD: 'Senha',
  CLICK: 'Clique',
  HERE: 'aqui',
  BACK_TO_LOGIN: 'para voltar ao login',
  CONTINUE: 'Continuar',
  BACK: 'Voltar',
  ACCOUNT_RECOVERY: 'Recuperação de conta',
  CONFIRM_PASSWORD: 'Confirmar Senha',
  THE__MALE_SINGULAR: 'o',
  THE__MALE_PLURAL: 'os',
  THE__FEMALE_SINGULAR: 'a',
  THE__FEMALE_PLURAL: 'as',
  AND: 'e'
};

export const pt_BR = {
  ...login,
  ...cadastro,
  ...recuperarContaNovaSenha,
  ...confirmacaoEmail,
  ...recuperarContaEmail,
  ...recuperarContaOpcoes,
  ...app,
  ...shared
};
