import styled, { css } from 'styled-components';

export const StyledSelect = styled.select`
  ${({ removeArrows }) =>
    removeArrows &&
    css`
      ::-webkit-inner-spin-button,
      ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    `}
`;
