import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import CheckSuccess from '../../../../styles/images/check-circle-success.svg';
import BoxBlue from '../../../../styles/images/box-blue.svg';
import AstronautArrow from '../../../../styles/images/astronaut-arrow.svg';
import i18n from '../../../../shared/util/i18n';
import Config from '../../../../shared/Config';
import { CONFIRMA_PAGINA_SUCESSO } from './queries';
import { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { getToken } from '../../../../shared/util/utilFunction';

export default function CadastroConcluido({ setShowFornecedor }) {
  const [confirmaPaginaSucesso] = useMutation(CONFIRMA_PAGINA_SUCESSO, {
    context: { headers: { authorization: getToken() } }
  });

  useEffect(() => {
    setShowFornecedor(false);
    confirmaPaginaSucesso();
    // eslint-disable-next-line
  }, []);

  const handlePanelLink = () => {
    window.location.href = `${Config.plataformaUiUrl}/painel`;
  };

  const handleSolutionLink = () => {
    window.location.href = `${Config.plataformaUiUrl}/catalogo`;
  };

  return (
    <Container className='container'>
      <div className='success-container'>
        <div className='success-wrap'>
          <img src={CheckSuccess} alt='Success Icon' className='icon-success' />
          <h1 className='success-message'>
            {i18n.t('SUCCESSFUL_REGISTRATION')}
          </h1>
        </div>

        <div className='description'>
          <p>{i18n.t('START_NOW')}</p>
        </div>
      </div>

      <div className='options-container'>
        <div className='astronaut'>
          <Link to='#' onClick={handlePanelLink} className='astronaut-link'>
            <img
              src={AstronautArrow}
              alt='Go to Panel'
              style={{ fontSize: 60 }}
            />
            <span>{i18n.t('GO_TO_PANEL')}</span>
          </Link>
        </div>
        <div className='box'>
          <Link to='#' onClick={handleSolutionLink} className='box-link'>
            <img
              src={BoxBlue}
              alt='Check Solutions'
              style={{ color: 'var(--color-white)', fontSize: 60 }}
            />
            <span>{i18n.t('CHECK_SOLUTIONS')}</span>
          </Link>
        </div>
      </div>
    </Container>
  );
}
