import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import Back from '../styles/images/ic/ic_chevron_danger.svg';
import Avatar from '../styles/images/avatar.svg';
import { Input } from '../components';
import Config from '../shared/Config';
import { getMessageError } from '../shared/util/utilFunction';
import { useAlert } from 'react-alert';
import i18n from '../shared/util/i18n';
import { TipoErroCadastroEnum } from '../shared/util/enums/TipoErroCadastroEnum';
import { SistemaAtualContext } from '../integracao-login/sistema-atual-context';
import {
  enterToRecover,
  enterYour,
  getIdentificador,
  getMask,
  icon
} from '../integracao-login/integracao-login';

export default function RecuperarContaEmail() {
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [sistemaAtual] = useContext(SistemaAtualContext);
  const [msgError, setMsgError] = useState();

  function obterOpcoes(event) {
    event.preventDefault();
    const identificador = event.target.identificador.value;
    if (!identificador) {
      alert.error(i18n.t(enterYour(sistemaAtual)));
      return;
    }

    setMsgError();
    setLoading(true);
    axios
      .get(`${Config.apiUrl}/recuperacao-senha/obter-opcoes/${identificador}`)
      .then(res => {
        localStorage.setItem('opcoes-recuperacao', JSON.stringify(res.data));
        localStorage.setItem('identificador-recuperacao', identificador);
        setLoading(false);
        history.push('/recuperar-conta-opcoes');
      })
      .catch(err => {
        const msg = getMessageError(err);
        if (
          msg === TipoErroCadastroEnum.ATENDIMENTO ||
          msg === TipoErroCadastroEnum.USUARIO_NAO_CADASTRADO
        ) {
          setMsgError(msg);
        } else {
          setMsgError();
          alert.error(msg);
        }
        setLoading(false);
      });
  }

  return (
    <div className='media-body padding-container'>
      <div className='row'>
        <img className='ml-3' src={Back} alt='ic_chevron_danger' />
        <Link className='d-flex align-items-end text-danger' to='/'>
          {i18n.t('BACK')}
        </Link>
      </div>
      <div className='recuperar-conta-div'>
        <div className='text-center'>
          <h2 className='text-black'>
            <strong>{i18n.t('ACCOUNT_RECOVERY')}</strong>
          </h2>
        </div>
        <br />
        <form className='mx-5 my-4 form-body' onSubmit={obterOpcoes}>
          <div className='mini-card text-center p-3'>
            <img src={Avatar} className='avatar' alt='avatar' />
            <div className='nome-vazio mt-2 mx-4'></div>
          </div>
          <br />
          <span className='d-flex text-center'>
            <strong>{i18n.t(enterToRecover(sistemaAtual))}</strong>
          </span>
          <br />

          {msgError === TipoErroCadastroEnum.ATENDIMENTO && (
            <p className='text-center font-weight-bold text-danger'>
              {i18n.t('DOES_NOT_OPTIONS')} {i18n.t('CONTACT_SERVICE_CENTER')}
            </p>
          )}

          {msgError === TipoErroCadastroEnum.USUARIO_NAO_CADASTRADO && (
            <p className='text-center font-weight-bold text-danger'>
              {i18n.t('DONT_EXISTS')}{' '}
              <Link to='/cadastro'>{i18n.t('CREATE_YOUR_ACCOUNT')}</Link>.
            </p>
          )}

          <Input
            icon={icon(sistemaAtual)}
            iconPos='left'
            placeholder={i18n.t(getIdentificador(sistemaAtual))}
            name='identificador'
            mask={getMask(sistemaAtual)}
          />
          <div className='d-flex justify-content-center'>
            <button
              type='submit'
              className='btn btn-primary rounded-pill col mt-5 py-2'
              customClass='col mt-3'
              disabled={loading && 'disabled'}>
              <div className='container-spinner'>
                <div
                  className={loading && 'spinner-border spinner-border-sm mr-2'}
                />
                <div>{i18n.t('CONTINUE')}</div>
              </div>
            </button>
          </div>
        </form>
        <br />
        <br />
        <div className='text-center mt-5 mb-3'>
          <span>
            {i18n.t('CLICK')}{' '}
            <Link to='/' className='text-primary'>
              {i18n.t('HERE')}
            </Link>{' '}
            {i18n.t('BACK_TO_LOGIN')}
          </span>
        </div>
      </div>
    </div>
  );
}
