import styled from 'styled-components';

export const Container = styled.div`
  .success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    height: auto;
    margin: 0 auto;
    padding-top: 156px;
  }

  .success-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .success-message {
    text-align: center;
    font-size: 26px/30px;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: var(--success);
    opacity: 1;
  }

  .icon-success {
    text-align: center;
    color: var(--success);
    margin-bottom: 1rem;
    max-width: 50px;
    width: 50px;
    opacity: 1;
  }

  .description {
    text-align: center;
    font-size: 13px/19px;
    letter-spacing: 0px;
    color: var(--text-color);
    opacity: 1;
  }

  .options-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 37px auto 0 auto;
    gap: 33px;
  }

  .box,
  .astronaut {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .astronaut-link {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .box-link {
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .astronaut {
    background: var(--white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px var(--color-gray-shadow);
    border-radius: 19px;
    opacity: 1;
    border: 1px solid var(--color-primary);
    span {
      max-width: 96px;
      width: 96px;
      height: 30px;
      text-align: center;
      font-size: 13px/15px;
      margin-top: 14px;
      font-weight: 500;
      letter-spacing: 0.65px;
      color: var(--primary);
      opacity: 1;
    }
  }

  .box {
    background-color: var(--primary);
    box-shadow: 0px 0px 10px var(--color-gray-shadow);
    border-radius: 19px;
    opacity: 1;
    span {
      max-width: 106px;
      text-align: center;
      color: var(--white);
      margin-top: 14px;
    }
  }

  .margin-icon {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 992px) {
    .min-height-success {
      min-height: 352px;
    }
  }

  @media (max-width: 420px) {
    .options-container {
      flex-direction: column;
    }
  }

  @media (max-width: 575px) {
    .margin-icon {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    .success-container {
      width: 100%;
      padding-top: 0;
    }

    .options-container {
      width: 100%;
    }

    .box,
    .astronaut {
      max-width: 180px;
      width: 100%;
      height: 183px;
      flex-direction: row;
      padding: 20px 10px 20px 10px;
      span {
        max-width: none;
      }
    }
  }
`;
