import { gql } from 'apollo-boost';

export const OBTER_TERMOS_E_POLITICA = gql`
  query {
    termos: obterTermoVigentePorTipo(tipo: USUARIO) {
      id
      texto
      vigente
    }
    politica: obterTermoVigentePorTipo(tipo: PRIVACIDADE) {
      id
      texto
      vigente
    }
  }
`;

export const CONTABILIZAR_LINK_AFILIADO = gql`
  mutation ContabilizarCadastroLinkAfiliado($linkAfiliadoId: String!) {
    contabilizarCadastroLinkAfiliado(linkAfiliadoId: $linkAfiliadoId) {
      id
    }
  }
`;
