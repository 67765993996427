import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import Config from './shared/Config';
import { Provider, positions, transitions } from 'react-alert';
import App from './App';
import { AlertModal } from './components';
import * as serviceWorker from './serviceWorker';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
if (path) {
  if (path.startsWith('/login')) {
    history.replace(path);
  } else {
    history.replace('/login' + path);
  }
}

if (!Config.baseUrl.includes('localhost:3000')) {
  Sentry.init({
    dsn: Config.sentryDsn,
    integrations: [
      new Integrations.BrowserTracing(),
      new RewriteFramesIntegration({
        // Retira o contexto da URL para o correto funcionamento do SourceMap
        iteratee: frame => {
          if (frame.filename) {
            frame.filename = frame.filename.replace('/login/', '/');
          }
          return frame;
        }
      })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: Config.environment
  });
}

ReactDOM.render(
  <Provider
    template={AlertModal}
    position={positions.TOP_CENTER}
    timeout={10000}
    transition={transitions.FADE}
    containerStyle={{ height: '50px', width: '100%' }}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
