import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { Input, Button, ErrorSpan } from '../../components';
import {
  getMessageError,
  saveTokenLocalStorage
} from '../../shared/util/utilFunction';
import { StyledModal, Backdrop, Container } from './styles';
import Config from '../../shared/Config';
import { useMutation, useQuery } from 'react-apollo';
import { OBTER_TERMOS_E_POLITICA, CONTABILIZAR_LINK_AFILIADO } from './queries';
//import { passwordRules } from '../shared/util/passwordRules';
import PasswordStrengthBar from 'react-password-strength-bar';
import Question from '../../styles/images/ic/ic_question.svg';
import { isValid } from 'cpf';
import i18n from '../../shared/util/i18n';
import Output from 'editorjs-react-renderer';
import { useHistory } from 'react-router-dom';
import { TipoErroCadastroEnum } from '../../shared/util/enums/TipoErroCadastroEnum';
import { Trans } from 'react-i18next';

export default function Cadastro(props) {
  const inputNome = useRef(null);
  const passwordRef = useRef(null);
  const alert = useAlert();
  const history = useHistory();
  //const [hints, setHints] = useState(null);
  const [password, setPassword] = useState('');
  const [msgError, setMsgError] = useState();
  const [tooltip, setTooltip] = useState(false);
  const [show, setShow] = useState(false);
  const [togglePrivacy, setTogglePrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aceitePrivacidade, setAceitePrivacidade] = useState(false);
  const [aceiteTermoUso, setAceiteTermoUso] = useState(false);
  const [aceitePromocoes, setAceitePromocoes] = useState(false);

  const { data } = useQuery(OBTER_TERMOS_E_POLITICA, {});

  const [contabilizarCadastroLinkAfiliado] = useMutation(
    CONTABILIZAR_LINK_AFILIADO
  );

  const renderBackdrop = props => <Backdrop {...props} />;

  const {
    register,
    handleSubmit,
    formState,
    errors,
    watch,
    setError,
    triggerValidation,
    clearError
  } = useForm();

  useEffect(() => {
    inputNome.current.focus();
  }, [inputNome]);

  useEffect(() => {
    props.setShowFornecedor(false);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (
  //     hints &&
  //     hints[0].status &&
  //     hints[1].status &&
  //     hints[2].status &&
  //     hints[3].status &&
  //     hints[4].status
  //   ) {
  //     clearError('senha');
  //   } else {
  //     setError('senha');
  //   }
  //   // eslint-disable-next-line
  // }, [password]);

  const onSubmit = data => {
    if (aceitePrivacidade === false || aceiteTermoUso === false) {
      return;
    }

    if (!data) {
      return;
    }
    const formatData = {
      celular: data.celular,
      confirmacaoSenha: data.confirmacaoSenha,
      cpf: data.cpf.replace(/\D+/g, ''),
      email: data.email,
      nome: data.nome,
      senha: data.senha,
      aceitePromocoes: aceitePromocoes
    };

    setLoading(true);
    setMsgError();
    axios
      .post(`${Config.apiUrl}/usuario/cadastrar`, formatData)
      .then(res => {
        if (res && res.data && res.data.accessToken) {
          saveTokenLocalStorage(res.data.accessToken, res.data.refreshToken);
          if (localStorage.getItem('linkAfiliadoId')) {
            handleHaveAffiliateLink();
          }

          history.push('/como-descobriu');
        } else {
          alert.error('Token não informada');
        }
        setLoading(false);
      })
      .catch(err => {
        setError('nome');
        setError('email');
        setError('cpf');
        setError('celular');
        setError('senha');
        setError('confirmacaoSenha');
        const msg = getMessageError(err);
        if (
          msg === TipoErroCadastroEnum.ATENDIMENTO ||
          msg === TipoErroCadastroEnum.RECUPERAR_SENHA
        ) {
          setMsgError(msg);
        } else {
          setMsgError();
          alert.error(msg);
        }
        setLoading(false);
      });
  };

  const handleHaveAffiliateLink = async () => {
    await contabilizarCadastroLinkAfiliado({
      variables: {
        linkAfiliadoId: localStorage.getItem('linkAfiliadoId')
      }
    });
  };

  const passwordChange = e => {
    //setHints(passwordRules(e.target.value));
    setPassword(e.target.value);
  };

  const toggleHover = () => {
    setTooltip(!tooltip);
  };

  const handleChangeScore = () => {
    triggerValidation('senha');
  };

  const validarCPF = async cpf => {
    if (!isValid(cpf)) {
      return i18n.t('INAVLID_CPF');
    }
  };

  return (
    <div className='cadastro-div'>
      <div className='text-center'>
        <h1 className='text-black' style={{ fontSize: '28px' }}>
          <strong>{i18n.t('CREATE_YOUR_ACCOUNT')}</strong>
        </h1>
        <br />
        <p className='text-black'>
          {i18n.t('REGISTER_FOR_A_PERSONALIZED_EXPERIENCE')}{' '}
          <span className='text-primary'> {i18n.t('SEBRAEPLACE')}</span>.
        </p>
      </div>
      <Container className='form-body'>
        <br />
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          {msgError === TipoErroCadastroEnum.ATENDIMENTO && (
            <p className='text-center font-weight-bold text-danger'>
              {i18n.t('ALREADY_EXISTS')} {i18n.t('CONTACT_SERVICE_CENTER')}
            </p>
          )}

          {msgError === TipoErroCadastroEnum.RECUPERAR_SENHA && (
            <p className='text-center font-weight-bold text-danger'>
              {i18n.t('ALREADY_EXISTS')}{' '}
              <Link to='/recuperar-conta'>
                {i18n.t('TRY_RECOVERY_PASSWORD')}
              </Link>
              .
            </p>
          )}

          <Input
            icon='name'
            iconPos='left'
            placeholder={i18n.t('FULL_NAME')}
            name='nome'
            invalid={errors.nome}
            isSubmitted={formState.isSubmitted}
            ref={e => {
              register(e, {
                required: true,
                pattern: {
                  value: /\S +\S/,
                  message: i18n.t('FILL_FULL_NAME')
                }
              });
              inputNome.current = e;
            }}
          />
          <ErrorSpan {...errors.nome} />

          <br />
          <Input
            icon='email'
            iconPos='left'
            placeholder={i18n.t('EMAIL')}
            name='email'
            invalid={errors.email}
            isSubmitted={formState.isSubmitted}
            ref={register({
              required: true,
              pattern: {
                // eslint-disable-next-line no-useless-escape
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: i18n.t('INVALID_EMAIL')
              }
            })}
            autocomplete='email'
          />
          <ErrorSpan {...errors.email} />

          <br />
          <Input
            icon='cpf'
            iconPos='left'
            placeholder={i18n.t('CPF')}
            name='cpf'
            invalid={errors.cpf}
            isSubmitted={formState.isSubmitted}
            mask={'999.999.999-99'}
            isValid={validarCPF}
            onChange={async e => {
              const cpf = e.target.value.replace(/\D/g, '');
              clearError('cpf');
              if (cpf && cpf.length === 11) {
                const result = await validarCPF(cpf);
                if (result) {
                  setError('cpf', 'manual', result);
                }
              }
            }}
            ref={e => {
              register(e, {
                required: true,
                validate: validarCPF
              });
            }}
            autocomplete='cpf'
          />
          <ErrorSpan {...errors.cpf} />

          <br />
          <Input
            icon='cell'
            iconPos='left'
            placeholder={i18n.t('CELLPHONE')}
            name='celular'
            invalid={errors.celular}
            isSubmitted={formState.isSubmitted}
            ref={register({
              required: true,
              pattern: {
                value: /\(\d{2}\) \d{5}-\d{4}/,
                message: i18n.t('INVALID_CELLPHONE')
              }
            })}
            mask={[
              '(',
              /\d/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
          />
          <ErrorSpan {...errors.celular} />

          <br />
          <div className='container-password-icon'>
            {tooltip && (
              <div className='tooltip-password-info'>
                <span>{i18n.t('CREATE_VALID_PASSWORD')}</span>
              </div>
            )}

            <Input
              type='password'
              icon='password'
              iconPos='left'
              placeholder={i18n.t('PASSWORD')}
              name='senha'
              invalid={errors.senha}
              isSubmitted={formState.isSubmitted}
              ref={register({
                required: true,
                validate: () => {
                  return (
                    (passwordRef &&
                      passwordRef.current &&
                      passwordRef.current.state &&
                      passwordRef.current.state.score > 1) ||
                    'A força da senha deve ser pelo menos Média'
                  );
                }
                // validate: value => {
                //   const passwordValid = passwordRules(value);
                //   return (
                //     (passwordValid &&
                //       passwordValid[0].status &&
                //       passwordValid[1].status &&
                //       passwordValid[2].status &&
                //       passwordValid[3].status &&
                //       passwordValid[4].status) ||
                //     'Senha inválida'
                //   );
                // }
              })}
              onChange={passwordChange}
              isPassword={true}
            />

            <img
              src={Question}
              className='icon-password-info'
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
              alt='password-question-icon'
            />
          </div>

          <ErrorSpan {...errors.senha} />

          {watch('senha') && watch('senha').length > 0 && (
            <PasswordStrengthBar
              password={password}
              scoreWords={[
                i18n.t('WEAK'),
                i18n.t('FAIR'),
                i18n.t('GOOD'),
                i18n.t('STRONG'),
                i18n.t('VERY_STRONG')
              ]}
              shortScoreWord={i18n.t('TOO_SHORT')}
              onChangeScore={() => handleChangeScore()}
              ref={passwordRef}
            />
          )}

          {!watch('senha') && <br />}
          <Input
            type='password'
            icon='password'
            iconPos='left'
            placeholder={i18n.t('CONFIRM_PASSWORD')}
            name='confirmacaoSenha'
            invalid={errors.confirmacaoSenha}
            isSubmitted={formState.isSubmitted}
            ref={register({
              required: true,
              validate: value =>
                value === watch('senha') || i18n.t('PASSWORD_NOT_MATCH')
            })}
          />
          <ErrorSpan {...errors.confirmacaoSenha} />

          <div className='termosCadastroWrapper mt-4'>
            <div className='checkbox-wrapper mb-2'>
              <input
                type='checkbox'
                id='aceiteivacidade'
                checked={aceitePrivacidade}
                onChange={() => setAceitePrivacidade(!aceitePrivacidade)}
              />
              <label
                htmlFor='aceitePrivacidade'
                className='font-size-12 place-self-center-start mb-0'>
                <Trans
                  i18nKey='PRIVACY_POLICY'
                  components={[
                    <span
                      key='PRIVACY_POLICY'
                      className='color-blue'
                      onClick={() => {
                        setTogglePrivacy(true);
                        setShow(true);
                      }}
                    />
                  ]}
                />
              </label>
              <span className='font-size-12 color-red'>
                {i18n.t('MANDATORY')}
              </span>
            </div>

            <div className='checkbox-wrapper mb-2'>
              <input
                type='checkbox'
                id='aceiteTermoUso'
                checked={aceiteTermoUso}
                onChange={() => setAceiteTermoUso(!aceiteTermoUso)}
              />
              <label
                htmlFor='aceiteTermoUso'
                className='font-size-12 place-self-center-start mb-0'>
                <Trans
                  i18nKey='USE_TERMS'
                  components={[
                    <span
                      key='USE_TERMS'
                      className='color-blue'
                      onClick={() => {
                        setTogglePrivacy(false);
                        setShow(true);
                      }}
                    />
                  ]}
                />
              </label>
              <span className='font-size-12 color-red'>
                {i18n.t('MANDATORY')}
              </span>
            </div>

            <div className='checkbox-wrapper'>
              <input
                type='checkbox'
                id='aceitePromocoes'
                checked={aceitePromocoes}
                onChange={() => setAceitePromocoes(!aceitePromocoes)}
              />
              <label
                htmlFor='aceitePromocoes'
                className='font-size-12 place-self-center-start mb-0'>
                <div>{i18n.t('CONTENT_PROMOTION')} </div>
              </label>
              <span className='font-size-12 color-red'>
                {i18n.t('OPTIONAL')}
              </span>
            </div>
          </div>

          <StyledModal
            show={show}
            onHide={() => setShow(false)}
            renderBackdrop={renderBackdrop}
            aria-labelledby='modal-label'>
            <div className='modal-content'>
              <button
                type='button'
                onClick={() => setShow(false)}
                className='button-close'>
                <span className='button-close-x'>X</span>
              </button>
              <div className='modal-title'>
                <h4 id='modal-label'>
                  {togglePrivacy ? 'Política de Privacidade' : 'Termos de uso'}
                </h4>
              </div>
              <div className='modal-text'>
                <p>
                  <Output
                    data={
                      data && data.politica && togglePrivacy
                        ? JSON.parse(data.politica.texto)
                        : data && data.termos && !togglePrivacy
                        ? JSON.parse(data.termos.texto)
                        : { block: [] }
                    }
                  />
                </p>
              </div>
              <div className='button-footer'>
                <Button
                  text='Ok'
                  shape='round'
                  customClass='col mt-3'
                  onClick={() => setShow(false)}
                />
              </div>
            </div>
          </StyledModal>

          <button
            type='submit'
            className='btn btn-primary rounded-pill col mt-3'
            customClass='col mt-3'
            disabled={loading && 'disabled'}>
            <div className='container-spinner'>
              <div
                className={loading && 'spinner-border spinner-border-sm mr-2'}
              />
              <div>{i18n.t('CONTINUE')}</div>
            </div>
          </button>
        </form>
        <br />
        <div className='text-center'>
          <span>
            {i18n.t('ALREADY_HAVE_ACCOUNT')}{' '}
            <Link to='/' className='text-primary'>
              {i18n.t('ACCESS')}
            </Link>
          </span>
        </div>
      </Container>
    </div>
  );
}
